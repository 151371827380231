import styled from "styled-components";

import {
  backgroundColor,
  borders,
  boxShadow,
  grids,
  layout,
  opacity,
  overflow,
  positioning,
  space,
  typography,
} from "@xstyled/system";
import type {
  BackgroundColorProps,
  BordersProps,
  BoxShadowProps,
  FlexboxesProps,
  GridsProps,
  LayoutProps,
  OpacityProps,
  OverflowProps,
  PositioningProps,
  SpaceProps,
  TypographyProps,
} from "@xstyled/system";

import { shouldForwardProp } from "../../should-forward-prop";
import { flexboxes } from "../../system";
import { ToolkitBase } from "../../types";

export interface BoxProps
  extends BordersProps,
    BackgroundColorProps,
    FlexboxesProps,
    TypographyProps,
    SpaceProps,
    GridsProps,
    LayoutProps,
    OpacityProps,
    OverflowProps,
    PositioningProps,
    BoxShadowProps,
    ToolkitBase {}

export const Box = styled("div").withConfig({
  shouldForwardProp: (prop, defaultValidtorFn) =>
    shouldForwardProp(prop) && defaultValidtorFn(prop),
})<BoxProps>`
  margin: 0;
  padding: 0;

  ${backgroundColor};
  ${flexboxes};
  ${typography};
  ${space};
  ${grids};
  ${layout};
  ${borders};
  ${opacity};
  ${overflow};
  ${positioning};
  ${boxShadow};
`;
