import React, { forwardRef } from "react";
import type { ReactNode } from "react";
import styled from "styled-components";

import { Box } from "../box";
import type { BoxProps } from "../box";

export interface SectionProps extends BoxProps {
  isFullWidth?: boolean;
  className?: string;
  children: ReactNode;
  id?: string;
}

const getContentWidth = (
  isFullWidth = false
): Array<number | string> | string => {
  if (isFullWidth) return "none";

  return ["none", 708, 1160];
};

const Content = styled(Box)<SectionProps>`
  box-sizing: content-box;
`;

export const Section = forwardRef(
  ({ isFullWidth, children, id, ...rest }: SectionProps, ref): JSX.Element => {
    return (
      <Box id={id} ref={ref} {...rest}>
        <Content
          px={["20px", "35px"]}
          mx="auto"
          maxWidth={getContentWidth(isFullWidth!)}
        >
          {children}
        </Content>
      </Box>
    );
  }
);

Section.defaultProps = {
  isFullWidth: false,
};

Section.displayName = "Section";
