import React from "react";
import type { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

import {
  backgroundColor,
  borders,
  boxShadow,
  layout,
  overflow,
  positioning,
  space,
  typography,
} from "@xstyled/system";
import type {
  BackgroundColorProps,
  BordersProps,
  BoxShadowProps,
  FlexboxesProps,
  LayoutProps,
  OverflowProps,
  PositioningProps,
  SpaceProps,
  TypographyProps,
} from "@xstyled/system";

import { shouldForwardProp } from "../../should-forward-prop";
import { flexboxes } from "../../system";
import type { ToolkitBase } from "../../types";

export interface InputProps
  extends BordersProps,
    BackgroundColorProps,
    FlexboxesProps,
    TypographyProps,
    SpaceProps,
    LayoutProps,
    OverflowProps,
    PositioningProps,
    BoxShadowProps,
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "size" | "height" | "width" | "color"
    >,
    ToolkitBase {}

const defaultStyles = {
  color: "darkMofo",
  fontFamily: "normal",
  fontSize: [1, 2],
  px: ["12px", "15px"],
  py: ["9px", "11px"],
  extra: css<{ hoverColor?: string }>`
    background-color: ${({ theme }) => theme.colors.white};
    outline: 0;
    margin: 0;
    padding: 0;
    line-height: normal;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.fieldBorder};
    border-radius: 5px;
    width: 100%;
    appearance: none;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
      color 0.3s ease-in-out;

    &::placeholder {
      opacity: 0.5;
      font-family: inherit;
      color: ${({ theme }) => theme.colors.darkMofo};
    }

    &:hover {
      border-color: ${({ theme, hoverColor }) =>
        (hoverColor && theme.colors[hoverColor]) || hoverColor || "#a3a7af"};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.brand};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brand};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      color: ${({ theme }) => theme.colors.dorianGray};
      background-color: ${({ theme }) => theme.colors.almostWhite};
      border-color: ${({ theme }) => theme.colors.fieldBorder};
    }
  `,
};

export const StyledInput = styled("input").withConfig({
  shouldForwardProp: (prop, defaultValidtorFn) =>
    shouldForwardProp(prop) && defaultValidtorFn(prop),
})<InputProps & HTMLInputElement>`
  ${backgroundColor};
  ${flexboxes};
  ${typography};
  ${space};
  ${layout};
  ${borders};
  ${overflow};
  ${positioning};
  ${boxShadow};
  ${({ extra }) => extra ?? extra};
`;

export const Input = (props: InputProps): JSX.Element => {
  return <StyledInput {...defaultStyles} {...props} />;
};
