import React, { ReactNode } from "react";
import styled from "styled-components";

import { Box } from "../box";
import { InputProps } from "../input";
import { Text } from "../text";

const CheckImage =
  "data:image/svg+xml,%3Csvg width='14' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-5 -7)' fill='none' fill-rule='evenodd'%3E%3Crect fill='none' width='24' height='24' rx='4'/%3E%3Cpath d='M17 7.34l-7.33 7.34-2.68-2.67a1.17 1.17 0 0 0-1.65 1.65l3.5 3.5c.46.45 1.2.45 1.65 0l8.17-8.17A1.17 1.17 0 0 0 17 7.34z' fill='%2377B203' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E";

const StyledInput = styled.input`
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: block;
  padding: 0;
  line-height: 25px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  text-align: center;
  border: solid 2px ${({ theme }) => theme.colors.divider};
  outline: 0;
  margin: 0 0 0 0;
  flex-shrink: 0;
  appearance: none;
  user-select: none;

  &:checked {
    border-color: ${({ theme }) => theme.colors.mofoGreen};
    background-image: url("${CheckImage}");
    background-position: 50%;
    background-repeat: no-repeat;
  }
`;

export interface CheckboxProps extends InputProps {
  label: string | ReactNode;
}

export const Checkbox = ({ label, ...rest }: CheckboxProps): JSX.Element => {
  let LabelComponent;
  if (typeof label === "string") {
    LabelComponent = (
      <Text as="span" fontSize={0}>
        {label}
      </Text>
    );
  } else {
    LabelComponent = label;
  }

  return (
    <Box as="label" display="flex" alignItems="center" position="relative">
      <StyledInput type="checkbox" {...rest} />
      <Box ml="10px">{LabelComponent}</Box>
    </Box>
  );
};
