import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { defaultToolkitTheme } from "./defaultToolkitTheme";

import type { DefaultTheme } from "styled-components";
import type { PropsWithChildren } from "react";

export interface ThemeProviderProps {
  theme: DefaultTheme;
}

export const ThemeProvider = ({
  theme,
  children,
}: PropsWithChildren<ThemeProviderProps>): JSX.Element => {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

ThemeProvider.defaultProps = {
  theme: defaultToolkitTheme,
};
