import {
  backgroundColor,
  borders,
  boxShadow,
  display,
  layout,
  opacity,
  overflow,
  positioning,
  space,
  typography,
} from "@xstyled/system";

import { flexboxes } from "./system";

const props: Array<string> = [];
const allProps = props.concat(
  backgroundColor.meta.props,
  borders.meta.props,
  boxShadow.meta.props,
  flexboxes.meta.props,
  layout.meta.props,
  opacity.meta.props,
  overflow.meta.props,
  positioning.meta.props,
  space.meta.props,
  typography.meta.props,
  display.meta.props
);

export const shouldForwardProp = (prop: string): boolean => {
  return !allProps.includes(prop);
};
