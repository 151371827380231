import React from "react";

import type { ReactElement } from "react";

export interface LogoProps {
  vinoColor?: string;
  mofoColor?: string;
}

export const Logo = ({ vinoColor, mofoColor }: LogoProps): ReactElement => {
  return (
    <svg viewBox="0 0 125 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.59.33h-6.02l.62 1.87v26.2l-.62 1.88h6.02l-.6-1.8V2.13l.6-1.8zm28.28 23.8V6.48s.1-2.2-1.6-2.2c-1.7 0-1.6 2.2-1.6 2.2v17.65s-.1 2.2 1.6 2.2c1.7 0 1.6-2.2 1.6-2.2zm4.81 0s.05 6.48-6.41 6.48-6.42-6.48-6.42-6.48V6.48S42.81 0 49.27 0s6.41 6.48 6.41 6.48v17.65zM35.18.33h-.59l.6 1.82v15.17h-.27l-.4-1.38L29.84.33h-4.89l.61 1.87v28.08h4.41v-17h.27l.4 1.4 4.68 15.6h4.28V2.06l.57-1.73h-4.98zM15 .33h.21l-.74 3.3h-.02l-4.44 26.65h-4.8L.75 3.62 0 .32h5.38L5.05 1.4l.13.91.2 1.31L7.3 16.8l.17 1.27h.27l.18-1.27L9.84 3.62h-.01l.26-1.66.08-.56L9.83.33H15z"
        fill={vinoColor}
      />
      <path
        d="M76.94 4.38V2.2l.61-1.87h-6.49L68.2 14.02l-.22 1.59h-.09l-.22-1.59L64.82.32h-6.5l.61 1.88v26.25l-.6 1.83H64l-.6-1.87V10.2h.26l.18 1.26 3.07 18.83h2.05l3.08-18.83.17-1.26h.27v18.28l-.59 1.8h5.66l-.61-1.88V4.38zM88.2 24.13V6.48s.09-2.2-1.6-2.2c-1.7 0-1.6 2.2-1.6 2.2v17.65s-.1 2.2 1.6 2.2c1.69 0 1.6-2.2 1.6-2.2zm4.81-17.65v17.65s.05 6.48-6.41 6.48c-6.47 0-6.42-6.48-6.42-6.48V6.48S80.14 0 86.61 0c6.46 0 6.41 6.48 6.41 6.48zm25.5 17.65V6.48s.08-2.2-1.6-2.2c-1.7 0-1.61 2.2-1.61 2.2v17.65s-.1 2.2 1.6 2.2c1.7 0 1.6-2.2 1.6-2.2zm4.8 0s.05 6.48-6.4 6.48c-6.47 0-6.42-6.48-6.42-6.48V6.48S110.45 0 116.9 0s6.42 6.48 6.42 6.48v17.65zM101.1 4.85v7.87h6.64v4.52h-6.64v11.2l.6 1.84h-6.02l.6-1.83V2.15l-.6-1.82h12.08l.82 5-1.64-.48h-5.85z"
        fill={mofoColor}
      />
    </svg>
  );
};

Logo.defaultProps = {
  vinoColor: "#ffffff",
  mofoColor: "#77B204",
};
