import React, { forwardRef } from "react";
import styled from "styled-components";

import {
  backgroundColor,
  borders,
  boxShadow,
  layout,
  overflow,
  positioning,
  space,
} from "@xstyled/system";
import type {
  BackgroundColorProps,
  BordersProps,
  BoxShadowProps,
  FlexboxesProps,
  LayoutProps,
  OverflowProps,
  PositioningProps,
  SpaceProps,
} from "@xstyled/system";

import { shouldForwardProp } from "../../should-forward-prop";
import { flexboxes } from "../../system";
import { ToolkitBase } from "../../types";

interface BaseProps
  extends BordersProps,
    BackgroundColorProps,
    BoxShadowProps,
    FlexboxesProps,
    SpaceProps,
    LayoutProps,
    OverflowProps,
    PositioningProps {}

export interface CardProps extends BaseProps, ToolkitBase {
  variant?: keyof typeof cardVariants;
}

const defaultStyles = {
  borderRadius: "5px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const cardVariants = {
  default: {
    ...defaultStyles,
    boxShadow: "0 5px 15px 0 rgba(0, 0, 0, 0.15)",
  },
  soft: {
    ...defaultStyles,
    boxShadow: "0 5px 25px 0 rgba(0, 0, 0, 0.10)",
  },
  hard: {
    ...defaultStyles,
    boxShadow: "0 5px 25px 0 rgba(0, 0, 0, 0.2)",
  },
};

export const StyledCard = styled("div").withConfig({
  shouldForwardProp: (prop, defaultValidtorFn) =>
    shouldForwardProp(prop) && defaultValidtorFn(prop),
})<BaseProps>`
  ${backgroundColor};
  ${boxShadow};
  ${flexboxes};
  ${space};
  ${layout};
  ${borders};
  ${positioning};
  ${overflow};
`;

export const Card = forwardRef(
  ({ variant, children, ...rest }: CardProps, ref): JSX.Element => {
    const styles = cardVariants[variant!];
    return (
      <StyledCard ref={ref} {...styles} {...rest}>
        {children}
      </StyledCard>
    );
  }
);

Card.defaultProps = {
  variant: "default",
};

Card.displayName = "Card";
