import React from "react";
import type { PropsWithChildren } from "react";
import styled from "styled-components";

import { typography } from "@xstyled/system";
import type { TypographyProps } from "@xstyled/system";

import { shouldForwardProp } from "../../should-forward-prop";
import type { ToolkitBase } from "../../types";

export interface LabelProps extends TypographyProps, ToolkitBase {
  htmlFor?: string;
}

const defaultStyles = {
  color: "darkMofo",
  fontFamily: "heading",
  fontSize: [0, 1],
  lineHeight: "1",
  fontWeight: "500",
};

export const StyledLabel = styled("label").withConfig({
  shouldForwardProp: (prop, defaultValidtorFn) =>
    shouldForwardProp(prop) && defaultValidtorFn(prop),
})<LabelProps & HTMLLabelElement>`
  ${typography};
`;

export const Label = ({
  children,
  ...rest
}: PropsWithChildren<LabelProps>): JSX.Element => {
  return (
    <StyledLabel {...defaultStyles} {...rest}>
      {children}
    </StyledLabel>
  );
};
