import * as CSS from "csstype";
import React, { PropsWithChildren } from "react";
import styled, { useTheme } from "styled-components";

import { ResponsiveValue } from "@xstyled/system";

import { Box } from "../box";

export const distributions = [
  "start",
  "middle",
  "end",
  "evenly",
  "fill",
] as const;
const spaceValues = [0, 1, 2, 3, 4, 5, 6, 7] as const;

type Distribution = typeof distributions[number];
type Space = typeof spaceValues[number];

export interface StackProps {
  space?: ResponsiveValue<Space>;
  horizontal?: boolean;
  distribute?: Distribution;
  reverse?: boolean;
  className?: string;
}

const getFlexDirection = ({
  horizontal,
  reverse,
}: Pick<StackProps, "horizontal" | "reverse">) => {
  const axis = horizontal ? "row" : "column";
  const flexDirection = reverse ? `${axis}-reverse` : axis;

  return flexDirection as CSS.Property.FlexDirection;
};

const getJustifyContent = ({ distribute }: Pick<StackProps, "distribute">) => {
  return {
    start: "flex-start",
    middle: "center",
    end: "flex-end",
    evenly: "space-between",
    fill: "flex-start",
  }[distribute!] as CSS.Property.JustifyContent;
};

const getSpacerSpace = ({
  horizontal,
  space,
  isFirstElement = false,
}: Pick<StackProps, "space" | "horizontal"> & { isFirstElement?: boolean }) => {
  if (isFirstElement) return;

  const theme = useTheme();

  const spaceSize = Array.isArray(space)
    ? space.map((s) => theme.spaces[s])
    : theme.spaces[space as number];

  return {
    mt: horizontal ? 0 : spaceSize,
    ml: horizontal ? spaceSize : 0,
  };
};

const StackItem = styled(Box)<
  Pick<StackProps, "horizontal"> & { fillParent: boolean }
>`
  flex: ${({ fillParent }) => (fillParent ? "1 1 0%" : null)};
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
`;

export const Stack = ({
  space = 0,
  distribute = "start",
  horizontal,
  reverse,
  children,
  ...rest
}: PropsWithChildren<StackProps>): JSX.Element => {
  const stackedItems = React.Children.toArray(children)
    .filter((child) => !!child)
    .map((child, index) => (
      <StackItem
        key={index}
        display="flex"
        horizontal={horizontal}
        fillParent={distribute === "fill"}
        {...getSpacerSpace({ isFirstElement: index === 0, horizontal, space })}
      >
        {child}
      </StackItem>
    ));

  return (
    <Box
      {...rest}
      display="flex"
      flexDirection={getFlexDirection({ horizontal, reverse })}
      justifyContent={getJustifyContent({ distribute })}
    >
      {stackedItems}
    </Box>
  );
};
