import { DefaultTheme } from "styled-components";

const mofoColors = {
  white: "#ffffff",
  fieldBackground: "#f5f7fa",
  background: "#eaecef",
  almostWhite: "#f7f8fa",
  divider: "#e2e2e2",
  disabled: "#d9d9d9",
  fieldBorder: "#e2e2e2",
  smurfette: "#e0f6ff",
  papaSmurf: "#1cb6f4",
  mystique: "#1392c5",
  neonGreen: "#cdff6b",
  mofoGreen: "#77b204",
  greenLantern: "#48a90f",
  bubbly: "#fff2d4",
  liquidGold: "#d6a871",
  charlie: "#fca403",
  sunlit: "#fca403",
  clockwork: "#ff6d21",
  pinky: "#ff62a5",
  deal: "#d51c5c",
  redRedWine: "#9a0945",
  dorianGray: "#626468",
  darkMofo: "#232627",
  vader: "#131314",
  black: "#131314",
};

const themeColors = {
  brand: mofoColors.mofoGreen,
  primary: mofoColors.greenLantern,
  secondary: mofoColors.vader,
  text: {
    heading: mofoColors.vader,
    normal: mofoColors.dorianGray,
    button: mofoColors.vader,
  },
  ...mofoColors,
};

const breakpointValues = [0, 768, 980, 1200];

const fontSizes = [
  12, 14, 16, 18, 20, 24, 26, 28, 30, 32, 36, 40, 42, 48, 64, 96,
];

export const defaultToolkitTheme: DefaultTheme = {
  colors: {
    ...themeColors,
  },
  spaces: [0, 10, 16, 20, 30, 40, 60, 100],
  breakpoints: breakpointValues.map((value) => `${value}px`),
  mq: {
    min: {
      tablet: `(min-width: ${breakpointValues[1] + 1}px)`,
      desktop: `(min-width: ${breakpointValues[2] + 1}px)`,
    },
    max: {
      mobile: `(max-width: ${breakpointValues[1] - 1}px)`,
      tablet: `(max-width: ${breakpointValues[2] - 1}px)`,
    },
  },
  fonts: {
    heading: `"GT Walsheim", sans-serif`,
    normal: `"Graphik", sans-serif`,
  },
  fontSizes: fontSizes,
  letterSpacings: {
    tight: "-0.025em",
    normal: "0em",
    wide: "0.025em",
  },
  lineHeights: {
    none: "1",
    short: "1.25",
    normal: "1.5",
    tall: "1.625",
  },
};

export type Theme = typeof defaultToolkitTheme;
