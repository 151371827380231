import React, { forwardRef } from "react";
import styled from "styled-components";

import { typography } from "@xstyled/system";
import type { TypographyProps } from "@xstyled/system";

import { shouldForwardProp } from "../../should-forward-prop";
import type { ToolkitBase } from "../../types";

export interface HeadingProps extends TypographyProps, ToolkitBase {
  variant?: keyof typeof headingVariants;
}

export const StyledHeading = styled("h2").withConfig({
  shouldForwardProp: (prop, defaultValidtorFn) =>
    shouldForwardProp(prop) && defaultValidtorFn(prop),
})<TypographyProps>`
  margin-top: 0;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${typography};
`;

const defaultStyles = {
  letterSpacing: "tight",
  lineHeight: "none",
  color: "text.heading",
  fontFamily: "heading",
};

export const headingVariants = {
  xxl: {
    ...defaultStyles,
    fontSize: [12, 13, 14],
  },
  xl: {
    ...defaultStyles,
    fontSize: [9, 11, 13],
  },
  lg: {
    ...defaultStyles,
    fontSize: [7, 10, 12],
  },
  md: {
    ...defaultStyles,
    fontSize: [5, 8, 10],
  },
  sm: {
    ...defaultStyles,
    fontSize: [4, 5, 7],
  },
  xs: {
    ...defaultStyles,
    fontSize: [3, 4, 5],
  },
  xxs: {
    ...defaultStyles,
    fontSize: [1, 2, 3],
  },
};

export const Heading = forwardRef(
  ({ variant, ...rest }: HeadingProps, ref): JSX.Element => {
    const styles = headingVariants[variant!];
    return <StyledHeading ref={ref} {...styles} {...rest} />;
  }
);

Heading.defaultProps = {
  variant: "md",
};

Heading.displayName = "Heading";
