import React from "react";
import styled from "styled-components";

import { typography } from "@xstyled/system";
import type { TypographyProps } from "@xstyled/system";

import { shouldForwardProp } from "../../should-forward-prop";
import type { ToolkitBase } from "../../types";

export interface FieldErrorMessageProps extends TypographyProps, ToolkitBase {
  message: string;
}

const defaultStyles = {
  color: "deal",
  fontFamily: "normal",
  fontSize: "12px",
  lineHeight: "1",
};

export const ErrorMessage = styled("p").withConfig({
  shouldForwardProp: (prop, defaultValidtorFn) =>
    shouldForwardProp(prop) && defaultValidtorFn(prop),
})<FieldErrorMessageProps>`
  margin: 0;
  ${typography};
`;

export const FieldErrorMessage = ({
  message,
  ...rest
}: FieldErrorMessageProps): JSX.Element => {
  return (
    <ErrorMessage {...defaultStyles} {...rest}>
      {message}
    </ErrorMessage>
  );
};
