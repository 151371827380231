import { shade, tint } from "polished";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

import {
  backgroundColor,
  borders,
  layout,
  space,
  typography,
} from "@xstyled/system";
import type {
  BackgroundColorProps,
  BordersProps,
  FlexboxesProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
} from "@xstyled/system";

import { shouldForwardProp } from "../../should-forward-prop";
import { flexboxes } from "../../system";
import type { ToolkitBase } from "../../types";

interface BaseButtonProps
  extends BordersProps,
    BackgroundColorProps,
    FlexboxesProps,
    TypographyProps,
    SpaceProps,
    LayoutProps,
    ToolkitBase {
  type?: string;
}

export interface ButtonProps extends BaseButtonProps {
  variant?: keyof typeof buttonVariants;
  onClick?: (e: React.MouseEvent) => void;
  hoverColor?: string;
  disabled?: boolean;
}

const defaultStyles = {
  fontFamily: "heading",
  backgroundColor: "transparent",
  borderWidth: 0,
  borderStyle: "none",
  boxSizing: "border-box",
  padding: 0,
  color: "text.button",
  extra: css<{ hoverColor?: string }>`
    cursor: pointer;
    appearance: none;
    outline: 0;
    &:hover {
      color: ${({ theme, hoverColor }) =>
        (hoverColor && theme.colors[hoverColor]) ||
        hoverColor ||
        theme.colors.brand};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      color: ${({ theme }) => theme.colors.text.button};
      &:hover {
        color: ${({ theme }) => theme.colors.text.button};
      }
    }
  `,
};

export const buttonVariants = {
  primary: {
    ...defaultStyles,
    fontSize: 2,
    fontWeight: "bold",
    borderRadius: "100px",
    padding: "16px 20px",
    lineHeight: "none",
    color: "white",
    backgroundColor: "greenLantern",
    borderColor: "greenLantern",
    borderWidth: "1px",
    extra: defaultStyles.extra.concat(css<{ hoverColor?: string }>`
      &:hover,
      &:focus {
        color: ${({ theme, hoverColor }) =>
          (hoverColor && theme.colors[hoverColor]) ||
          hoverColor ||
          theme.colors.white};

        border-color: ${({ theme }) => tint(0.1, theme.colors.greenLantern)};
        background-color: ${({ theme }) =>
          tint(0.1, theme.colors.greenLantern)};
      }

      &:active {
        border-color: ${({ theme }) => shade(0.1, theme.colors.greenLantern)};
        background-color: ${({ theme }) =>
          shade(0.1, theme.colors.greenLantern)};
      }

      &:disabled {
        border-color: ${({ theme }) => theme.colors.disabled};
        background-color: ${({ theme }) => theme.colors.disabled};
        cursor: not-allowed;
        opacity: 0.5;
      }
    `),
  },
  outline: {
    ...defaultStyles,
    fontSize: 2,
    fontWeight: 500,
    borderRadius: "100px",
    padding: "14px 18px",
    lineHeight: "none",
    backgroundColor: "white",
    border: "2px solid",
    borderColor: "greenLantern",
    extra: defaultStyles.extra.concat(css<{ hoverColor?: string }>`
      &:hover,
      &:focus {
        color: ${({ theme, hoverColor }) =>
          (hoverColor && theme.colors[hoverColor]) ||
          hoverColor ||
          theme.colors.black};

        border-color: ${({ theme }) => tint(0.1, theme.colors.primary)};
        background-color: ${({ theme }) => tint(0.95, theme.colors.primary)};
      }

      &:active {
        border-color: ${({ theme }) => shade(0.1, theme.colors.primary)};
        background-color: ${({ theme }) => tint(0.95, theme.colors.primary)};
      }

      &:disabled {
        border-color: ${({ theme }) => theme.colors.disabled};
        background-color: ${({ theme }) => theme.colors.white};
        cursor: not-allowed;
        opacity: 0.5;
      }
    `),
  },
  default: {
    ...defaultStyles,
  },
};

export const StyledButton = styled("button").withConfig({
  shouldForwardProp: (prop, defaultValidtorFn) =>
    shouldForwardProp(prop) && defaultValidtorFn(prop),
})<BaseButtonProps & { extra?: string }>`
  ${backgroundColor};
  ${flexboxes};
  ${typography};
  ${space};
  ${layout};
  ${borders};
  ${({ extra }) => extra ?? extra};
`;

export const Button = forwardRef(
  ({ variant, ...rest }: ButtonProps, ref): JSX.Element => {
    const styles = buttonVariants[variant!];
    return <StyledButton ref={ref} {...styles} {...rest} />;
  }
);

Button.defaultProps = {
  variant: "default",
};

Button.displayName = "Button";
